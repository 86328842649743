<template>
  <b-container fluid class="card-details">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div class="card-container">
                            <div class="d-flex justify-content-center">
                                <b-img style="border-radius: 50%" class="mt-2 mb-2 height-150 width-150" fluid :src="cardApplicationRequest.attachment" alt="smart-card-image" />
                            </div>
                          <b-table class="card-modal smart-card-table" striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.cardApplicationRequest = this.item
  },
  data () {
    return {
      cardApplicationRequest: {},
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('complain.organization'), val: this.cardApplicationRequest.org_name_bn, key1: this.$t('cardPayment.id_serial'), val1: this.$n(this.cardApplicationRequest.id_serial, { useGrouping: false }) },
          { key: this.$t('cardPayment.applicant_name'), val: this.cardApplicationRequest.name_bn, key1: this.$t('cardPayment.email'), val1: this.cardApplicationRequest.email },
          { key: this.$t('cardPayment.father_name'), val: this.cardApplicationRequest.father_name_bn, key1: this.$t('cardPayment.mother_name'), val1: this.cardApplicationRequest.mother_name_bn },
          { key: this.$t('cardPayment.marital_status'), val: this.cardApplicationRequest.marital_status, key1: this.$t('cardPayment.spouse_name'), val1: this.cardApplicationRequest.spouse_name_bn },
          { key: this.$t('cardPayment.no_of_child'), val: this.$n(this.cardApplicationRequest.no_of_child, { useGrouping: false }), key1: this.$t('cardPayment.nid'), val1: this.$n(this.cardApplicationRequest.nid, { useGrouping: false }) },
          { key: this.$t('cardPayment.mobile_no'), val: this.$n('0' + this.cardApplicationRequest.mobile_no, { useGrouping: false }), key1: this.$t('cardPayment.gender'), val1: this.cardApplicationRequest.gender },
          { key: this.$t('complain.division'), val: this.cardApplicationRequest.division_name_bn, key1: this.$t('complain.district'), val1: this.cardApplicationRequest.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.cardApplicationRequest.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.cardApplicationRequest.union_name_bn },
          { key: this.$t('farmerOperator.village'), val: this.cardApplicationRequest.far_village_bn, key1: this.$t('cardPayment.ward_no'), val1: this.$n(this.cardApplicationRequest.ward_no, { useGrouping: false }) }
        ]
      } else {
          return [
            { key: this.$t('complain.organization'), val: this.cardApplicationRequest.org_name, key1: this.$t('cardPayment.id_serial'), val1: this.cardApplicationRequest.id_serial },
            { key: this.$t('cardPayment.applicant_name'), val: this.cardApplicationRequest.name, key1: this.$t('cardPayment.email'), val1: this.cardApplicationRequest.email },
            { key: this.$t('cardPayment.father_name'), val: this.cardApplicationRequest.father_name, key1: this.$t('cardPayment.mother_name'), val1: this.cardApplicationRequest.mother_name },
            { key: this.$t('cardPayment.marital_status'), val: this.cardApplicationRequest.marital_status, key1: this.$t('cardPayment.spouse_name'), val1: this.cardApplicationRequest.spouse_name },
            { key: this.$t('cardPayment.no_of_child'), val: this.cardApplicationRequest.no_of_child, key1: this.$t('cardPayment.nid'), val1: this.cardApplicationRequest.nid },
            { key: this.$t('cardPayment.mobile_no'), val: this.cardApplicationRequest.mobile_no, key1: this.$t('cardPayment.gender'), val1: this.cardApplicationRequest.gender },
            { key: this.$t('complain.division'), val: this.cardApplicationRequest.division_name, key1: this.$t('complain.district'), val1: this.cardApplicationRequest.district_name },
            { key: this.$t('complain.upazila'), val: this.cardApplicationRequest.upazilla_name, key1: this.$t('complain.union'), val1: this.cardApplicationRequest.union_name },
            { key: this.$t('farmerOperator.village'), val: this.cardApplicationRequest.far_village, key1: this.$t('cardPayment.ward_no'), val1: this.cardApplicationRequest.ward_no }
          ]
      }
    }
  },
  methods: {
  }
}
</script>
<style>
  .card-details {
    /* background-image: url("../../../../../assets/images/login/bg1.jpg");
    background-repeat: no-repeat;
    background-size: cover; */
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }

  .card-modal tbody {
    font-size: 11px;
    border: 1px solid #dee2e6;
    color: #FFF;
    text-shadow: 1px 1px 1px #000;
    font-weight: 800;
  }
  .card-container {
      background-image: url("../../../../../assets/images/login/bg1.jpg");
      background-repeat: no-repeat;
      background-size: cover;
  }
  .smart-card-table {
  }
  .smart-card-table tr:hover {
    color: #ff8282 !important;
  }
  /* [dir=ltr][mode=light] .table-hover tbody tr:hover {
    color: #ff8282 !important;
  } */
</style>
