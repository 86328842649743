<template>
    <b-container fluid>
        <b-row v-if="loadMsg">
            <b-col>
                <b-overlay  :show='true'>
                    <h4 class="text-center">{{ $t('globalTrans.loading') }} ...</h4>
                </b-overlay>
            </b-col>
        </b-row>
        <div v-else>
            <b-row v-if="overlay" variant="default" show>
                <div class="box-message col-md-12 mt-5 text-danger">
                    <div class="arrow">
                        <div class="outer"></div>
                        <div class="inner"></div>
                    </div>
                    <div class="message-body">
                        <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>
                            <!-- {{ $t('externalUserIrrigation.dashboard_message') }} -->
                            {{ $t('externalPanel.profile_complete_msg_part1')}}
                            <router-link :to="{ path: '/irrigation/my-profile' }">
                            {{ $t('externalPanel.profile_complete_msg_part2')}}
                            </router-link>
                            {{ $t('externalPanel.profile_complete_msg_part3')}}
                        </p>
                    </div>
                </div>
            </b-row>
            <div v-else>
                <div>
                    <b-row>
                        <b-col md="12">
                            <b-modal id="modal-card" size="lg" :title="$t('cardPayment.card')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                                <CardModal :item="item"/>
                            </b-modal>
                            <b-modal id="view-modal" size="lg"
                                    :title="$t('farCardApplication.smartCardInfo')"
                                    :ok-title="$t('globalTrans.close')" ok-only
                                    ok-variant="danger">
                                <Show :id="itemId"/>
                            </b-modal>
                            <b-modal id="modal-payment" size="md" :title="$t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                                <Payment :singleData="singleApp"/>
                            </b-modal>
                            <b-modal id="modal-reissue" size="md" :title="$t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                                <Reissue :application="singleApp"/>
                            </b-modal>
                            <b-modal id="modal-money-receipt" size="md" :title="$t('externalUserIrrigation.money_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                                <MoneyReceipt :appId="currentAppId" :applicationType="applicationType" :paymentTypeTd="paymentTypeTd" />
                            </b-modal>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">{{ $t('externalUserIrrigation.smart_card_application') }}</h4>
                                </template>
                                <template v-slot:body>
                                    <b-overlay :show="loadview">
                                        <b-row>
                                            <b-col>
                                                <div class="stepper-wrapper">
                                                    <div :class="(listData[0].status >= 1) ? `stepper-item completed`: 'stepper-item'">
                                                        <div class="step-counter"><i v-if="listData[0].status >= 1" class="ri-check-fill"></i> <span v-else>1</span></div>
                                                        <div class="step-name">{{ getStatus(1) }}</div>
                                                    </div>
                                                    <div :class="(listData[0].status >= 2) ? `stepper-item completed`: 'stepper-item'">
                                                        <div class="step-counter"><i v-if="listData[0].status > 2" class="ri-check-fill"></i> <span v-else>2</span></div>
                                                        <div class="step-name">{{ getStatus(2) }}</div>
                                                    </div>
                                                    <div :class="(listData[0].status >= 3) ? `stepper-item completed`: 'stepper-item'">
                                                        <div class="step-counter"><i v-if="listData[0].status > 3" class="ri-check-fill"></i> <span v-else>3</span></div>
                                                        <div class="step-name">{{ getStatus(3) }}</div>
                                                    </div>
                                                    <slot v-if="listData[0].status > 3">
                                                        <div :class="(listData[0].status >= 4) ? `stepper-item completed`: 'stepper-item'">
                                                            <div class="step-counter"><i v-if="listData[0].status > 4" class="ri-check-fill"></i> <span v-else>4</span></div>
                                                            <div class="step-name">{{ getStatus(4) }}</div>
                                                        </div>
                                                        <div :class="(listData[0].status >= 5) ? `stepper-item completed`: 'stepper-item'">
                                                            <div class="step-counter"><i v-if="listData[0].status > 5" class="ri-check-fill"></i> <span v-else>5</span></div>
                                                            <div class="step-name">{{ getStatus(5) }}</div>
                                                        </div>
                                                        <div v-if="listData[0].status === 6" :class="(listData[0].status >= 6) ? `stepper-item completed`: 'stepper-item'">
                                                            <div class="step-counter"><i v-if="listData[0].status > 6" class="ri-check-fill"></i> <span v-else>6</span></div>
                                                            <div class="step-name">{{ getStatus(6) }}</div>
                                                        </div>
                                                        <div v-if="listData[0].status === 7" :class="(listData[0].status >= 7) ? `stepper-item completed`: 'stepper-item'">
                                                            <div class="step-counter"><i v-if="listData[0].status > 7" class="ri-check-fill"></i> <span v-else>7</span></div>
                                                            <div class="step-name">{{ getStatus(7) }}</div>
                                                        </div>
                                                    </slot>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-table-simple bordered>
                                                    <tr>
                                                        <th>{{ $t('externalUserIrrigation.fee') }}</th>
                                                        <th>{{ $t('externalUserIrrigation.money_receipt') }}</th>
                                                        <th>{{ $t('globalTrans.action') }}</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="fee">
                                                            <b-button v-if="listData[0].payment_status === 0"
                                                                class="btn-outline-success"
                                                                variant=" iq-bg-success ml-1"
                                                                size="sm"
                                                                title="Pending Payment"
                                                                @click="payment(listData[0])">
                                                                {{ $t('externalUserIrrigation.payment') }}
                                                            </b-button>
                                                        </td>
                                                        <td class="money-receipt">
                                                            <b-button v-if="listData[0].payment_status >= 1"
                                                                class="btn-outline-success mt-1"
                                                                variant=" iq-bg-success ml-1"
                                                                size="sm"
                                                                @click="showMoneyReceipt(listData[0], 3, 1)"
                                                                >
                                                                {{ $t('externalUserIrrigation.application_fee') }}
                                                            </b-button>
                                                            <b-button v-if="listData[0].payment_status === 2"
                                                                class="btn-outline-success mt-1"
                                                                variant=" iq-bg-success ml-1"
                                                                size="sm"
                                                                @click="showMoneyReceipt(listData[0], 3, 2)"
                                                                >
                                                                {{ $t('externalUserIrrigation.reissue') }}
                                                            </b-button>
                                                        </td>
                                                        <td class="action">
                                                            <router-link
                                                                v-if="reapplyCheck(listData[0])"
                                                                :to='{ path: "/irrigation/smart-card-application/"+ listData[0].id }'>
                                                                <b-button
                                                                    @click="setReissueStatus"
                                                                    class="btn-outline-success ml-1"
                                                                    variant=" iq-bg-success mr-1"
                                                                    size="sm"
                                                                    title="Re-apply Application">
                                                                    {{
                                                                        $t('externalUserIrrigation.reapply')
                                                                    }}
                                                                </b-button>
                                                            </router-link>
                                                            <b-button
                                                                v-if="listData[0].status === 5"
                                                                @click="reissue(listData[0])"
                                                                class="btn-outline-success ml-1"
                                                                variant="iq-bg-success mr-1"
                                                                size="sm"
                                                                title="Re-apply Application">
                                                                {{
                                                                    $t('externalUserIrrigation.reissue')
                                                                }}
                                                            </b-button>
                                                            <router-link
                                                                v-if="listData[0].payment_status === 0"
                                                                :to='{ path: "/irrigation/smart-card-application/edit/"+ listData[0].id }'>
                                                                <b-button
                                                                    class="btn-outline-success"
                                                                    variant=" iq-bg-success"
                                                                    size="sm"
                                                                    title="Edit Application"
                                                                >
                                                                    <i class="ri-ball-pen-fill m-0"></i>
                                                                </b-button>
                                                            </router-link>
                                                            <b-button v-if="listData[0].status === 4"
                                                                class="btn-outline-success ml-1"
                                                                title="Card View" v-b-modal.modal-card variant=" iq-bg-success" size="sm"
                                                                @click="cardView(listData[0])">
                                                                {{ $t('globalTrans.print') }}
                                                            </b-button>
                                                            <b-button v-if="listData[0].rating_status !== 1"
                                                                class="btn-outline-success ml-1"
                                                                title="Rate This Application"
                                                                v-b-modal.modal-rating variant=" iq-bg-success" size="sm"
                                                                @click="rating(listData[0])">
                                                                {{ $t('external_sidebar.rating') }}
                                                            </b-button>
                                                        </td>
                                                    </tr>
                                                </b-table-simple>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <Show :id="listData[0].id"/>
                                        </b-row>
                                    </b-overlay>
                                </template>
                            </iq-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <b-modal id="modal-rating" size="md" :title="$t('external_sidebar.rating')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Rating :application="singleApp"/>
        </b-modal>
    </b-container>
</template>

<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerInfo, smartCardApplicationList, irrigationPayment, smartCardGeneratedList } from '../../api/routes'
import { Common, helpers, List } from '@/mixins/helper-functions'
import Show from '@/modules/external-user-service/irrigation/pages/smart-card-application/Show'
import customObject from '../../../../../store/custom'
import Payment from './Payment'
import Reissue from './Reissue'
import CardModal from './CardModal'
import Rating from '../../components/Rating'
import MoneyReceipt from './MoneyReceipt'
export default {
    name: 'Smart-card-application-list',
    mixins: [List, Common],
    components: { Show, Payment, Reissue, CardModal, Rating, MoneyReceipt },
    data () {
        return {
            loadview: false,
            loadMsg: false,
            dataFetched: false,
            loadTable: true,
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.ExternalUserIrrigation.static.perPage,
                slOffset: 1
            },
            search: {},
            itemId: null,
            item: '',
            singleApp: null,
            rows: [],
            overlay: true,
            currentAppId: 0,
            applicationType: 0,
            paymentTypeTd: 0,
            attachmentPrefix: irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/smart-card-apps/original/'
        }
    },
    computed: {
        formTitle () {
            return (this.itemId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
        },
        loading () {
            return this.$store.state.ExternalUserIrrigation.static.loading
        },
        listReload () {
            return this.$store.state.ExternalUserIrrigation.static.listReload
        },
        loadingState () {
            if (this.listReload || this.loading) {
                return true
            } else {
                return false
            }
        },
        listData () {
            return this.$store.state.ExternalUserIrrigation.smartCardApplicationLists
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.application_id'), class: 'text-left' },
                { label: this.$t('farmerOperator.applicant_name'), class: 'text-left' },
                { label: this.$t('globalTrans.address'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.reissue_status'), class: 'text-left' },
                { label: this.$t('globalTrans.print'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.review_note'), class: 'text-left' },
                { label: this.$t('externalUserIrrigation.reject_note'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-right' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'application_id' },
                { key: 'name' },
                { key: 'address' },
                { key: 'status_en' },
                { key: 'reissue_status' },
                { key: 'print' },
                { key: 'review_note' },
                { key: 'reject_note' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[2] = { key: 'name_bn' }
                keys[4] = { key: 'status_bn' }
                keys[7] = { key: 'review_note_bn' }
                keys[8] = { key: 'reject_note_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState: function (newVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    created () {
        this.checkUser().then((res) => {
            if (!res.smart_card_id) {
                this.overlay = true
            } else {
                this.loadData()
            }
        })
        this.paymentStatusUpdate()
    },
    methods: {
        cardView (item) {
            this.item = item
        },
        getAddress (item) {
            const isBn = this.$i18n.locale === 'bn'
            const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
            const divisionList = commonObj.divisionList.find(division => division.value === parseInt(item.far_division_id))
            const divisionEnBn = typeof divisionList === 'undefined' ? '' : divisionList.text
            const districtList = commonObj.districtList.find(district => district.value === parseInt(item.far_district_id))
            const districtEnBn = typeof districtList === 'undefined' ? '' : districtList.text
            const upazilaList = commonObj.upazilaList.find(upazila => upazila.value === parseInt(item.far_upazila_id))
            const upazilaEnBn = typeof upazilaList === 'undefined' ? '' : upazilaList.text
            const unionList = commonObj.unionList.find(union => union.value === parseInt(item.far_union_id))
            const unionEnBn = typeof unionList === 'undefined' ? '' : unionList.text

            return isBn ? item.far_village_bn : item.far_village +
                ', ' +
                'Ward No : ' +
                item.ward_no +
                ', ' + unionEnBn +
                ', ' +
                upazilaEnBn +
                ', ' + districtEnBn +
                ', ' +
                divisionEnBn
        },
        setReissueStatus () {
            // this.$store.dispatch('ExternalUserIrrigation/setReissueStatus', 2)
        },
        reissue (item) {
            this.singleApp = item
            this.$bvModal.show('modal-reissue')
        },
        reapplyCheck (data) {
            const status = customObject.smartCardStatus.find(item => item.name === 'Reviewed')
            return data.status === (status ? status.id : null)
        },
        checkEditable (data) {
            const status = customObject.smartCardStatus.find(item => item.name === 'Pending')
            return data.status === (status ? status.id : null)
        },
        view (item) {
            this.itemId = item.id
            this.$bvModal.show('view-modal')
        },
        payment (item) {
            this.singleApp = item
            this.$bvModal.show('modal-payment')
        },
        rating (item) {
            this.singleApp = item
            this.singleApp.application_type = 3
            this.$bvModal.show('modal-rating')
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length
            }
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage,
                farmer_id: this.$store.state.Auth.authUser.id
            })
            this.loadMsg = true
            RestApi.getData(irriSchemeServiceBaseUrl, smartCardApplicationList, params).then((response) => {
                this.loadTable = false
                this.loadMsg = false
                if (response.data.data.length < 1) {
                    this.$router.push('/irrigation/smart-card-application-form')
                }
                this.singleApp = response.data.data[0]
                if (this.singleApp.rating_status === 0 && this.singleApp.status >= 3 && this.singleApp.status <= 5) {
                    this.rating(response.data.data[0])
                }
                return this.mixinLoadData(
                    irriSchemeServiceBaseUrl,
                    smartCardApplicationList,
                    'ExternalUserIrrigation/getSMCApplicationList',
                    this.dataList(response)).then(() => {
                    this.dataFetched = true
                })
            })
            RestApi.getData(irriSchemeServiceBaseUrl, smartCardGeneratedList, params).then(response => {
                if (response.success) {
                this.$store.dispatch('setList', this.dataList(response))
                this.paginationData(response.data)
                } else {
                this.$store.dispatch('setList', [])
                }
            })
        },
        dataList (response) {
            const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
            let listData = {}
            const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
            const divisionList = commonObj.divisionList
            const districtList = commonObj.districtList
            const upazilaList = commonObj.upazilaList
            const unionList = commonObj.unionList
            listData = response.data.data.map(item => {
                const divisionObject = divisionList.find(division => division.value === item.far_division_id)
                const districtObject = districtList.find(district => district.value === item.far_district_id)
                const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
                const unionObject = unionList.find(union => union.value === item.far_union_id)
                const orgTmp = orgList.find(orgItem => orgItem.value === item.org_id)
                const status = customObject.smartCardStatus.find(e => e.id === item.status)
                const data = {
                    org_name: orgTmp.text_en,
                    org_name_bn: orgTmp.text_bn,
                    status_en: status.name,
                    status_bn: status.name_bn
                }
                const divisionData = {
                    division_name: divisionObject !== undefined ? divisionObject.text_en : '',
                    division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
                }
                const districtData = {
                    district_name: districtObject !== undefined ? districtObject.text_en : '',
                    district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
                }
                const upazillaData = {
                    upazilla_name: districtObject !== undefined ? upazilaObject.text_en : '',
                    upazilla_name_bn: districtObject !== undefined ? upazilaObject.text_bn : ''
                }
                const unionData = {
                    union_name: unionObject !== undefined ? unionObject.text_en : '',
                    union_name_bn: unionObject !== undefined ? unionObject.text_bn : ''
                }
                item.attachment = this.attachmentPrefix + item.attachment
                return Object.assign({},
                    item, data,
                    divisionData, districtData,
                    upazillaData, unionData)
            })
            // delete response.data.data
            listData = Object.assign(listData, response.data)
            return listData
        },
        getReissueStatus (id) {
            return helpers.findEnBn(customObject.reissueStatus, id, 'id', 'name', 'name_bn')
        },
        getStatus (id) {
            return helpers.findEnBn(customObject.smartCardStatus, id, 'id', 'name', 'name_bn')
        },
        async checkUser () {
            this.loadMsg = true
            return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
                this.loadMsg = false
                if (response.success) {
                    this.overlay = false
                    this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
                    return { smart_card_id: response?.data.id }
                } else {
                    return { smart_card_id: 0 }
                }
            })
        },
        async paymentStatusUpdate () {
            if (this.$route.params.status && this.$route.query.transId) {
                const status = this.$route.params.status
                const tranId = this.$route.query.transId
                await RestApi.getData(irriSchemeServiceBaseUrl, irrigationPayment + status, { transId: tranId }).then(response => {
                    if (response.success) {
                        this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#D6E09B'
                        })
                    } else {
                        this.$toast.error({
                        title: 'Error',
                        message: response.message
                        })
                    }
                })
                this.$router.push('/irrigation/smart-card-application')
            }
        },
        showMoneyReceipt (item, appType, paymentTypeTd) {
            this.currentAppId = item.id
            this.applicationType = appType
            this.paymentTypeTd = paymentTypeTd
            this.$bvModal.show('modal-money-receipt')
        }
    }
}
</script>
<style scoped>
    .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
</style>
