import i18n from '@/i18n'
import Store from '@/store'
// import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

function getBase64ImageFromURL (url) {
    return new Promise((resolve, reject) => {
        var img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')

        img.onload = () => {
            var canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height

            var ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)

            var dataURL = canvas.toDataURL('image/png')

            resolve(dataURL)
        }

        img.onerror = error => {
            reject(error)
        }

        img.src = url
    })
}

const exportPdfDetails = async (reportTitle, thisObject, data) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
            { text: reportTitle, style: 'header2', alignment: 'center' }
        ]

        pdfContent.push([
            { text: reportTitle, style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHead = [
            [
            { text: thisObject.$t('externalUserIrrigation.reissue_status'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.application.reissue_status_en : data.application.reissue_status_bn, alignment: 'left', style: 'search' },
            { text: thisObject.$t('globalTrans.status'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.application.status_en : data.application.status_bn, alignment: 'left', style: 'search' }
        ]
        ]

        pdfContent.push({
            table: {
                headerRows: 0,
                widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
                body: allRowsHead
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
            }
        })

        pdfContent.push([
            { text: thisObject.$t('globalTrans.official_information'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadSchemeDetails = [
            [
                { text: thisObject.$t('globalTrans.application_id'), alignment: 'right', style: 'search' },
                { text: ':', style: 'search', alignment: 'center' },
                { text: data.official.application_id, alignment: 'left', style: 'search' },
                { text: thisObject.$t('globalTrans.id_serial'), alignment: 'right', style: 'search' },
                { text: ':', style: 'search', alignment: 'center' },
                { text: thisObject.$n(data.official.id_serial, { useGrouping: false }), alignment: 'left', style: 'search' }
            ]
        ]

        allRowsHeadSchemeDetails.push([
            { text: thisObject.$t('org_pro.organization'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.official.org_name : data.official.org_name_bn, alignment: 'left', style: 'search' },
            { text: thisObject.$t('cardPayment.training_info'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.official.training_info : data.official.training_info, alignment: 'left', style: 'search' }
        ])

        pdfContent.push({
            table: {
                headerRows: 0,
                widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
                body: allRowsHeadSchemeDetails
            },
            layout: {
                hLineWidth: function (i, node) {
                return 0
                },
                vLineWidth: function (i, node) {
                return 0
                }
            }
        })

        // Personal Information
        pdfContent.push([
            { text: thisObject.$t('globalTrans.personal_information'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadPumpInfoDetails = [
            [
                { text: thisObject.$t('globalTrans.profileImage'), alignment: 'right', style: 'search' },
                { text: ':', style: 'search', alignment: 'center' },
                { image: data.personal.attachment !== null ? await getBase64ImageFromURL(data.personal.attachment) : '', width: 100, height: 100, style: 'search', alignment: 'center' },
                {},
                {},
                {}
            ]
        ]

        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('globalTrans.name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.personal.name : data.personal.name_bn, alignment: 'left', style: 'search' },
            {},
            {},
            {}
        ])

        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('globalUserData.email'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.personal.email : data.personal.email, alignment: 'left', style: 'search' },
            { text: thisObject.$t('globalUserData.mobile_no'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n('0') + thisObject.$n(data.personal.mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' }
        ])

        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('cardPayment.date_of_birth'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$d(new Date(data.personal.date_of_birth)), alignment: 'left', style: 'search' },
            { text: thisObject.$t('cardPayment.marital_status'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.personal.marital_status_en : data.personal.marital_status_bn, alignment: 'left', style: 'search' }
        ])

        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('cardPayment.father_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.personal.father_name : data.personal.father_name_bn, alignment: 'left', style: 'search' },
            { text: thisObject.$t('cardPayment.mother_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.personal.mother_name : data.personal.mother_name_bn, alignment: 'left', style: 'search' }
        ])

        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('globalUserData.gender'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.personal.gender_en : data.personal.gender_bn, alignment: 'left', style: 'search' },
            { text: thisObject.$t('cardPayment.no_of_child'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.personal.no_of_child, { useGrouping: false }), alignment: 'left', style: 'search' }
        ])
        allRowsHeadPumpInfoDetails.push([
            { text: thisObject.$t('globalUserData.earnings'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.personal.earnings, { useGrouping: false }), alignment: 'left', style: 'search' },
            { text: thisObject.$t('globalUserData.qualification'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: data.personal.qualification, alignment: 'left', style: 'search' }
        ])

        pdfContent.push({
            table: {
                headerRows: 0,
                widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
                body: allRowsHeadPumpInfoDetails
            },
            layout: {
                hLineWidth: function (i, node) {
                return 0
                },
                vLineWidth: function (i, node) {
                return 0
                }
            }
        })

        // Address
        pdfContent.push([
            { text: thisObject.$t('globalTrans.address'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadProject = [
            [
                { text: thisObject.$t('farmerOperator.village'), alignment: 'right', style: 'search' },
                { text: ':', style: 'search', alignment: 'center' },
                { text: i18n.locale === 'en' ? data.address.far_village : data.address.far_village_bn, alignment: 'left', style: 'search' },
                {},
                {},
                {}
            ]
        ]
        allRowsHeadProject.push([
            { text: thisObject.$t('complain.district'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.address.district_name : data.address.district_name_bn, alignment: 'left', style: 'search' },
            { text: thisObject.$t('complain.division'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.address.division_name : data.address.division_name_bn, alignment: 'left', style: 'search' }
        ])
        allRowsHeadProject.push([
            { text: thisObject.$t('complain.upazila'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.address.upazilla_name : data.address.upazilla_name_bn, alignment: 'left', style: 'search' },
            { text: thisObject.$t('complain.union'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.address.union_name : data.address.union_name_bn, alignment: 'left', style: 'search' }
        ])
        allRowsHeadProject.push([
            { text: thisObject.$t('cardPayment.ward_no'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.address.ward_no, { useGrouping: false }), alignment: 'left', style: 'search' },
            {},
            {},
            {}
        ])

        pdfContent.push({
            table: {
                headerRows: 0,
                widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
                body: allRowsHeadProject
            },
            layout: {
                hLineWidth: function (i, node) {
                return 0
                },
                vLineWidth: function (i, node) {
                return 0
                }
            }
        })

        // Land Information
        pdfContent.push([
            { text: thisObject.$t('globalTrans.land_information'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadLandInfo = [
            [
            { text: thisObject.$t('cardPayment.barga_land'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.land.barga_land, { useGrouping: false }), alignment: 'left', style: 'search' },
            { text: thisObject.$t('cardPayment.crop_plan'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.land.crop_plan : data.land.crop_plan_bn, alignment: 'left', style: 'search' }
            ]
        ]

        allRowsHeadLandInfo.push([
            { text: thisObject.$t('cardPayment.lease_land'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.land.lease_land, { useGrouping: false }), alignment: 'left', style: 'search' },
            { text: thisObject.$t('cardPayment.owned_land'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.land.owned_land, { useGrouping: false }), alignment: 'left', style: 'search' }
        ])

        allRowsHeadLandInfo.push([
            { text: thisObject.$t('cardPayment.total_land'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: thisObject.$n(data.land.total_land, { useGrouping: false }), alignment: 'left', style: 'search' },
            {},
            {},
            {}
        ])

        pdfContent.push({
            table: {
                headerRows: 0,
                widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
                body: allRowsHeadLandInfo
            },
            layout: {
                hLineWidth: function (i, node) {
                return 0
                },
                vLineWidth: function (i, node) {
                return 0
                }
            }
        })

        // Review note
        pdfContent.push([
            { text: thisObject.$t('globalTrans.other_information'), style: 'header3', alignment: 'center', decoration: 'underline' }
        ])

        const allRowsHeadReviewNote = [
            [
            { text: thisObject.$t('cardPayment.review_note'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.other.review_note : data.other.review_note_bn, alignment: 'left', style: 'search' }
            ]
        ]

        allRowsHeadReviewNote.push([
            { text: thisObject.$t('complain.reject_note'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.other.reject_note : data.other.reject_note_bn, alignment: 'left', style: 'search' }
        ])

        pdfContent.push({
            table: {
            headerRows: 0,
            widths: ['48%', '4%', '48%'],
            body: allRowsHeadReviewNote
            },
            layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
            }
        })

        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
            th: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 3, 3, 3],
                bold: true
            },
            td: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 3, 3, 3]
            },
            image: {
                width: 150,
                height: 150,
                margin: [3, 3, 3, 3]
            },
            search: {
                fontSize: (i18n === 'bn') ? 12 : 10,
                margin: [3, 3, 3, 3]
            },
            fertilizer: {
                margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            header: {
                fontSize: 12,
                margin: [0, 0, 0, 4]
            },
            header2: {
                fontSize: i18n.locale === 'bn' ? 14 : 12,
                margin: [0, 10, 0, 10]
            },
            header3: {
                fontSize: i18n.locale === 'bn' ? 13 : 11,
                margin: [0, 10, 0, 5]
            },
            headerPort1: {
                fontSize: 10,
                margin: [0, 20, 0, 5]
            },
            headerPort: {
                fontSize: 10,
                margin: [0, 4, 0, 15]
            },
            krishi: {
                margin: [0, -5, 0, 15],
                alignment: 'center'
            },
            address: {
                fontSize: 9,
                margin: [0, -10, 0, 0]
            },
            tableSubHead: {
                margin: [0, 5, 0, 15]
            }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }

    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
